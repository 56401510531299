import React, { Component } from 'react'
import axios from 'axios'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from 'reactstrap';
import {APIURL, CLOUDFRONT_URL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL, PORT} from '../../../config/config'
// IMAGE_THUMB_URL
import NumberFormat from 'react-number-format';
import * as moment from 'moment';
import 'moment/locale/th';
import {connect} from "react-redux";
import {CarSellUtil, CarsUtil} from "../../../utils/carSellUtil";
import {checkGroupPermission, getGroupPermission} from "../../../services/grouppermission";
import classnames from "classnames";
import CarRequestRepair from '../CarRequestRepair';
import Loading from "../../Loading";

const port = Number(window.env.PORT);

class CarRepairMainList extends Component {

  constructor (props) {
    super(props)

    this.state = {
      lists: [],
      load_data: true,
      toggle_car_status_edit: false,
      car_id: '',
        user: {},
        menuID: [3, 303],
        grouppermissions: [],
        activeTab: '1',
        center_list: [],
        center_id: '',
        isLoading: false
    }

      this.toggleCarStatusEdit = this.toggleCarStatusEdit.bind(this)
      this.onCarStatusChange = this.onCarStatusChange.bind(this)
      this.loadGroupPermission = this.loadGroupPermission.bind(this);
      this.toggle = this.toggle.bind(this);
  }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    loadGroupPermission() {
        let type = this.state.user.type
        let menuID = this.state.menuID
        getGroupPermission(type, menuID).then(res => {
            if(res.data.result === 'success') {
                this.setState({
                    grouppermissions: res.data.grouppermissions
                })
            }
        })
    }

    loadData () {
        let username = this.state.user.user;
        let center_id = this.state.center_id;
        // axios.get(APIURL + '/car/car_repair/mainlist/'+username)
        let data = {
            username: username,
            center_id: center_id
        }

        this.setState({
            isLoading: true
        }, () => {
            axios.post(APIURL + '/car/car_repair/search/', data)
                .then(res => {
                    this.setState({
                        lists: res.data,
                        load_data: false,
                        car_id: 0,
                        danger: false,
                        isLoading: false
                    });
                }).catch(error => {
                this.setState({lists: [], load_data: true, car_id: 0, danger: false, isLoading: false});
            });
        })

    }

    componentWillMount () {
        this.setState({
            user: JSON.parse(this.props.user),
           /* car_id: car.car_id,
            upload_code: car.upload_code*/
        });
    }

  componentDidMount () {
      CarsUtil.removeCarSelect()
      CarSellUtil.removeCarSellSelect()
    //if(this.state.load_data)
    this.loadGroupPermission()
    this.loadCenterData()
    this.loadData()
  }

    loadCenterData(){
        axios.get(APIURL + '/center')
            .then(res => {
                // todx: filter แจ้งซ่อม
                let user_type = this.state.user.type
                let center_list = res.data
                // console.log('user_type=', user_type)
                if (PORT === 9021 ) {
                    center_list = center_list.filter(center => !center.center_name.includes('แจ้งซ่อม'))
                }

                let center_list_exist_data = center_list.filter(center => center.repair_center_id !== 0)


                this.setState({center_list: center_list_exist_data })

            }).catch(error => {
            // alert("error"+error)
            this.setState({center_list: []})
        })
    }

  toggleCarStatusEdit(){
    this.setState({toggle_car_status_edit: !this.state.toggle_car_status_edit})
  }

  onCarStatusChange(){
    //alert("car_id="+car_id+" , car_status_id="+car_status_id)
    let car_status_id = 4
    let car_id = this.state.car_id
    axios.put(APIURL + '/car/car_status_repair_complete/'+car_id,{
      car_id: car_id,
      car_status_id: car_status_id,
    })
      .then(res => {
        this.loadData()
        this.toggleCarStatusEdit()
      }).catch(error => {

    })
  }


  render () {

      let center_list = this.state.center_list.map((center,index)=>{
          return( <option key={index} value={center.center_id}>{center.center_name}</option> )
      })

    let tbodyList = this.state.lists.map((car, index) => {

      let image_url = ''
      if (car.car_upload_filename.includes('https')) {
        let car_upload_key = car.car_upload_key
        // image_url = car.car_upload_filename
        image_url = CLOUDFRONT_URL+car_upload_key
      } else {
          if(car.car_upload_filename === null || car.car_upload_filename === '') {
              image_url = IMAGE_NOPHOTO_URL
          } else {
              image_url = IMAGE_FULL_URL + car.car_upload_filename
          }
      }

      // console.log(car)
      let order = index + 1
      const car_license_plate = car.car_license_plate_new !== '' &&  car.car_license_plate_new !==  car.car_license_plate_old ? car.car_license_plate_new : car.car_license_plate_old

      return (

        <tr key={index}>
          <td>{order}</td>
          <td>
            <img
              src={image_url}
              style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
              alt="ภาพเอกสาร"
            />
            <br/>
            <strong> {car.car_name}
            <br/>
            ทะเบียนรถ: {car_license_plate}
            </strong>
          </td>
          <td>
            ส่งซ่อม &nbsp;&nbsp;&nbsp;: {moment(car.car_repair_send).locale('th').format('LL')}
            <br/><br/>
            แล้วเสร็จ : {moment(car.car_repair_complete).locale('th').format('LL')}
            <br/><br/>
            อู่ : {car.center_name}
          </td>
          <td className={port === 9040 && this.state.user.type !== 'admin'? 'hidden' : "text-left"}>
            <NumberFormat value={car.car_repair_cost} displayType={'text'} thousandSeparator={true} prefix={'฿'} />
          </td>
          <td>
          <span
            className='badge'
            style={{backgroundColor: car.car_status_color}}
          >
             {car.car_status_name}
          </span>
            <br/>
            รอรับรถ {car.car_repair_count} รายการ
          </td>
          <td className="align-top">

              {
                  checkGroupPermission(3, this.state.grouppermissions).created ? (
                      <button type="button"
                              className={port === 9040 && this.state.user.type !== 'admin'? 'hidden' : "btn btn-danger btn-sm btn-block"}
                              onClick={()=>{
                                  var target = "/car-repair/new?car_id="+car.car_id+"&toggle=new" ;
                                  this.props.history.push(target);
                              }}
                      >
                          <i className="icon-wrench"/> เพิ่มรายการซ่อม
                      </button>
                  ) : null
              }

            {
                checkGroupPermission(3, this.state.grouppermissions).modified ? (
                    <button type="button"
                            className="btn btn-success btn-sm btn-block"
                            onClick={()=>{
                                this.setState({
                                    car_id: car.car_id
                                },()=>{
                                    this.toggleCarStatusEdit()
                                })
                            }}
                    >
                        <i className="icon-check"/> ซ่อมเสร็จ
                    </button>
                ) : null
            }

            {
                checkGroupPermission(3, this.state.grouppermissions).viewed ? (
                    <button type="button"
                            className="btn btn-info btn-sm btn-block"
                            onClick={()=>{
                                var target = "/car-repair/new?car_id="+car.car_id ;
                                this.props.history.push(target);
                            }}
                    >
                        <i className="icon-list"/> รายการซ่อม
                    </button>
                ) : null
            }

          </td>
        </tr>
      )
    })

    let tbodyEmpty = ()=>{
      return (
        <tr className="text-center">
          <td colSpan={6}><span className="text-danger">ไม่พบข้อมูล</span></td>
        </tr>
      )};

    /*<button className="btn btn-primary float-right">
                   <Link  to="/car-repair/new" style={{color: 'white'}} activeClassName="active" >
                   <i className="icon-plus"></i> เพิ่มข้อมูลการซ่อมบำรุง
                   </Link>
    </button>*/

    return (
<>
        <div className="col-md-12 mb-4">

            <Loading isLoading={this.state.isLoading} />

            <Nav tabs>


                {
                    checkGroupPermission(3, this.state.grouppermissions).viewed ?
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                            >
                                <i className="icon-compass"/> ข้อมูลการซ่อมบำรุงรถ
                            </NavLink>
                        </NavItem>
                        : null
                }

                {
                    checkGroupPermission(303, this.state.grouppermissions).viewed ?
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}
                            >
                                <i className="icon-game-controller"/> ข้อมูลการแจ้งซ่อม
                            </NavLink>
                        </NavItem>
                        : null
                }
            </Nav>

            <TabContent activeTab={this.state.activeTab}>
                {
                    checkGroupPermission(3, this.state.grouppermissions).viewed ?
                        <TabPane tabId="1">
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <div className="card card-accent-warning">
                                        <div className="card-header card-sparepart">
                                            <div className="form-inline">
                                                <strong className="text-title"> ข้อมูลการซ่อมบำรุงรถ </strong>

                                                <div className="ml-1" style={{width: 200}}>
                                                    <select
                                                        style={{width: 200}}
                                                        className="form-control"
                                                        name="center_id"
                                                        value={this.state.center_id}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                center_id: e.target.value
                                                            }, () => {
                                                                this.loadData()
                                                            })
                                                        }}
                                                    >
                                                        <option value="">เลือกศูนย์ซ่อม</option>
                                                        {center_list}
                                                    </select>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="card-block">
                                            <table className="table table-striped">
                                                <thead>
                                                <tr>
                                                    <th className="text-center">#</th>
                                                    <th className="text-center" width="20%">ข้อมูลรถ</th>
                                                    <th>วันที่ส่งซ่อม/แล้วเสร็จ</th>
                                                    <th className={port === 9040 && this.state.user.type !== 'admin'? 'hidden' : "text-left"}>ค่าซ่อมรวม</th>
                                                    <th>สถานะ</th>
                                                    <th className="text-center">จัดการ</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {(tbodyList.length>0)? tbodyList: tbodyEmpty()}
                                                </tbody>
                                            </table>
                                        </div>

                                        <Modal isOpen={this.state.toggle_car_status_edit} toggle={this.toggleCarStatusEdit} className="'modal-lg modal-warning">
                                            <ModalHeader toggle={this.toggleCarStatusEdit.bind(this)}>แก้ไขสถานะรถ</ModalHeader>
                                            <ModalBody>
                                                <div className="h5 text-gray-dark"> คุณต้องการแก้ไขสถานะรถ เป็น ซ่อมเสร็จ ใช่หรือไม่ ?? </div>
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color="secondary"
                                                        onClick={this.toggleCarStatusEdit.bind(this)}>ยกเลิก</Button>
                                                <Button color="primary"
                                                        onClick={this.onCarStatusChange.bind(this, this.state.car_doc_delete)}>ตกลง</Button>{' '}
                                            </ModalFooter>
                                        </Modal>

                                    </div>
                                </div>
                            </div>
                        </TabPane>
                        : null
                }

                <TabPane tabId="2">
                    {
                        this.state.activeTab === '2' && <CarRequestRepair
                            user={this.state.user}
                            grouppermissions={this.state.grouppermissions}
                        />
                    }
                </TabPane>
            </TabContent>
        </div>


</>
    )
  }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    type: state.auth.type,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarRepairMainList);
