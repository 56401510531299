import React, { Component } from 'react'
import Loading from '../Loading'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import axios from 'axios'
import { APIURL, PORT } from '../../config/config'
import { connect } from 'react-redux'
import { save } from 'save-file';
import { AlertWarning } from '../Alert/Alert'
import {CarUtil} from "../../utils/carUtil";
import {NumberUtil} from "../../utils/number-util";


class SellReport extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      date_start: new Date(),
      date_end: new Date(),
      sum_price_normal: 0,
      sum_price: 0,
      sum_profit: 0,
      sum_car_cost: 0,
      sum_car_cost_commissions: 0,
      sum_car_cost_repair: 0,
      sum_car_cost_other: 0,
      sum_car_cost_tax: 0,
      sum_car_cost_act: 0,
      //
      sum_car_external_commission: 0,
      sum_sales_commission: 0,
      sum_additional_commission: 0,
      sum_advertising_cost: 0,
      sum_delivery_fee: 0,
      sum_promotion_fee: 0,
      //
      sum_finance_fee: 0,
      sum_transfer_fee: 0,
      //
      sum_vat_sell: 0,
      sum_finance_commission: 0,
      sum_vat_commission: 0,
      lists: [],
      sum_cost_phra: 0,
      sum_cost_vat_and_property_tax: 0,

      person_vat_id: '0',
      employee_id : '',
      employees:[],

     //
     sum_cost_operation: 0,
     sum_cost_service: 0,
     sum_cost_transport: 0,
     sum_cost_porobor_lan: 0,
     sum_cost_bangkhab_phasi_lan: 0,
     sum_cost_bangkhab_xon_lan: 0,
     sum_cost_check_ton: 0,
     sum_cost_after_sales_service: 0,

     sum_tax_invoice_vat: 0,
     sum_vat_buy: 0,
     sum_car_cost_vat: 0,
     sum_financing_amount: 0,
     sum_tax_invoice_amount: 0,
     sum_down_total: 0
    }
    this.handleDateStart = this.handleDateStart.bind(this)
    this.handleDateEnd = this.handleDateEnd.bind(this)
    this.loadSellData = this.loadSellData.bind(this)
    this.loadEmployeeData = this.loadEmployeeData.bind(this)
  }

  handleDateStart(date){
    this.setState({
      date_start: date
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    })
  }

    componentDidMount() {
        this.mounted = true
        this.loadEmployeeData();
    }

  loadSellData() {

    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    const employee_id = this.state.employee_id
    const person_vat_id = this.state.person_vat_id

      const data = {
          start_date : start_date,
          end_date : end_date,
          employee_id : employee_id,
          person_vat_id : person_vat_id,
      }

    this.setState({
      isLoading: true
    })

      axios.post(APIURL + '/report/sell/', data)
      .then(res => {
        let sum_price_normal = 0
        let sum_price = 0
        let sum_car_cost = 0
        let sum_car_cost_repair = 0
        let sum_car_cost_other = 0
        let sum_car_cost_other2 = 0
        let sum_profit = 0
        let sum_car_cost_tax = 0
        let sum_car_cost_act = 0
        //
        let sum_vat_sell = 0
        let sum_finance_commission = 0
        let sum_vat_commission = 0
        let sum_car_external_commission = 0
        let sum_sales_commission = 0
        let sum_additional_commission = 0
        let sum_advertising_cost = 0
        let sum_delivery_fee = 0
        let sum_promotion_fee = 0
        let sum_finance_fee = 0
        let sum_transfer_fee = 0
        //
        let sum_car_cost_commissions = 0
        let sum_cost_phra = 0
        let sum_cost_vat_and_property_tax = 0

        let  person_vat_id = this.state.person_vat_id
        let  employee_id = this.state.employee_id

        //
          let sum_cost_operation = 0
          let sum_cost_service = 0
          let sum_cost_transport = 0
          let sum_cost_porobor_lan = 0
          let sum_cost_bangkhab_phasi_lan = 0
          let sum_cost_bangkhab_xon_lan = 0
          let sum_cost_check_ton = 0
          let sum_cost_after_sales_service = 0

          let sum_tax_invoice_vat = 0
          let sum_vat_buy = 0
          let sum_tax_invoice_amount = 0

          let sum_car_cost_vat = 0
          let sum_financing_amount = 0
          let sum_down_total = 0
          let sum_down_not_profit = 0


          if(res.data.length > 0) {

          sum_price_normal =  res.data.reduce((acc, next) => acc + next.normal_sell_price, 0)
          //sum_price =  res.data.reduce((acc, next) => acc + next.real_sell_price, 0) //ราคาขายจริง
          sum_price =  res.data.reduce((acc, next) => {
              let tmp = next.tax_invoice_amount > 0 ? next.tax_invoice_amount : next.real_sell_price
              return acc + tmp
          }, 0) //ราคาขายจริง, ยอดจัด

          if(PORT === 9005 || PORT === 9008){
              sum_price = res.data.reduce((acc, next) => acc + next.real_sell_price, 0)
          }

          sum_car_cost =  res.data.reduce((acc, next) => acc + next.car_cost, 0)

          //
          sum_car_cost_commissions = res.data.reduce((acc, next) => acc + next.car_cost_commissions, 0)
          sum_car_cost_repair =  res.data.reduce((acc, next) => acc + next.car_cost_repair, 0)
          sum_car_cost_tax = res.data.reduce((acc, next) => acc + next.car_cost_tax, 0)
          sum_car_cost_act = res.data.reduce((acc, next) => acc + next.car_cost_act, 0)
          sum_car_cost_other =  res.data.reduce((acc, next) => acc + next.car_cost_expenses_other, 0)
          sum_car_cost_other2 =  res.data.reduce((acc, next) => acc + next.car_cost_other, 0)
          sum_car_cost_other = sum_car_cost_other+sum_car_cost_other2

          // รายรับการขาย
          sum_vat_sell = res.data.reduce((acc, next) => acc + next.vat_sell, 0)
          sum_tax_invoice_vat = res.data.reduce((acc, next) => {
                let tmp = next.tax_invoice_vat > 0 ? next.tax_invoice_vat : next.vat_sell
                return acc + tmp
            }, 0)

            sum_finance_commission = res.data.reduce((acc, next) => {
                let tmp = next.tax_commission_amount > 0 ? next.tax_commission_amount : next.finance_commission
                return acc + tmp
            }, 0)

            sum_vat_commission = res.data.reduce((acc, next) => {
                let tmp = next.tax_commission_vat > 0 ? next.tax_commission_vat : next.vat_commission
                return acc + tmp
            }, 0)

            sum_tax_invoice_amount = res.data.reduce((acc, next) => {
                let tmp = next.tax_invoice_amount > 0 ? next.tax_invoice_amount : next.rate_finance
                return acc + tmp
            }, 0)

            sum_down_total = res.data.reduce((acc, next) => acc + next.sum_down, 0)

            sum_down_not_profit = res.data.filter(d=>(d.tax_invoice_vat + d.tax_invoice_amount) === d.sum_down).reduce((acc, next) => acc + next.sum_down, 0)

          // รายจ่ายการขาย
            sum_cost_operation = res.data.reduce((acc, next) => acc + next.cost_operation, 0) //ค่าดำเนินการ
            sum_cost_service = res.data.reduce((acc, next) => acc + next.cost_service, 0)//ค่าบริการ
            sum_cost_transport = res.data.reduce((acc, next) => acc + next.cost_transport, 0)//ค่าขนย้าย
            sum_cost_porobor_lan = res.data.reduce((acc, next) => acc + next.cost_porobor_lan, 0)//ค่า พรบ. จากลาน
            sum_cost_bangkhab_phasi_lan = res.data.reduce((acc, next) => acc + next.cost_bangkhab_phasi_lan, 0)//ค่าบังคับต่อภาษี จากลาน
            sum_cost_bangkhab_xon_lan = res.data.reduce((acc, next) => acc + next.cost_bangkhab_xon_lan, 0)//ค่าบังคับโอนเข้าเต็นท์ จากลาน
            sum_cost_check_ton = res.data.reduce((acc, next) => acc + next.cost_check_ton, 0)//ค่าเช็คต้น

          sum_car_external_commission = res.data.reduce((acc, next) => acc + next.car_external_commission, 0)
          sum_sales_commission = res.data.reduce((acc, next) => acc + next.sales_commission, 0)
          sum_additional_commission = res.data.reduce((acc, next) => acc + next.additional_commission, 0)
          sum_advertising_cost = res.data.reduce((acc, next) => acc + next.advertising_cost, 0)
          sum_delivery_fee = res.data.reduce((acc, next) => acc + next.delivery_fee, 0)
          sum_promotion_fee = res.data.reduce((acc, next) => acc + next.promotion_fee, 0)
          // รายจ่ายการขาย 2
          sum_finance_fee = res.data.reduce((acc, next) => acc + next.finance_fee, 0) // ค่าธรรมเนียมไฟแนนท์
          sum_transfer_fee = res.data.reduce((acc, next) => acc + next.transfer_fee, 0) // ค่าโอนรถ

          sum_cost_after_sales_service = res.data.reduce((acc, next) => acc + next.cost_after_sales_service, 0)//บริการหลังการขาย

          sum_cost_phra = res.data.reduce((acc, next) => acc + next.cost_phra, 0)
          sum_cost_vat_and_property_tax = res.data.reduce((acc, next) => acc + next.cost_vat_and_property_tax, 0)
          sum_vat_buy = res.data.reduce((acc, next) => acc + next.vat_buy, 0)

          sum_profit = sum_price - (sum_car_cost + sum_vat_buy + sum_car_cost_commissions + sum_car_cost_repair + sum_car_cost_other+ sum_car_cost_tax + sum_car_cost_act) +
          (sum_tax_invoice_vat + sum_finance_commission + sum_vat_commission) -
          (sum_cost_operation + sum_cost_service + sum_cost_transport + sum_cost_porobor_lan + sum_cost_bangkhab_phasi_lan + sum_cost_bangkhab_xon_lan +
           sum_cost_check_ton + sum_car_external_commission + sum_sales_commission + sum_additional_commission + sum_advertising_cost + sum_delivery_fee +
           sum_promotion_fee + sum_finance_fee + sum_transfer_fee + sum_cost_after_sales_service + sum_cost_phra + sum_cost_vat_and_property_tax) + sum_down_total - sum_down_not_profit

            if(PORT === 9005 || PORT === 9008){
                sum_profit = sum_profit - sum_down_total
            }

          sum_financing_amount = sum_tax_invoice_amount + sum_tax_invoice_vat

          sum_car_cost_vat = (sum_car_cost + sum_vat_buy + sum_car_cost_commissions + sum_car_cost_repair + sum_car_cost_other+ sum_car_cost_tax + sum_car_cost_act) +
                             (sum_cost_operation + sum_cost_service + sum_cost_transport + sum_cost_porobor_lan + sum_cost_bangkhab_phasi_lan + sum_cost_bangkhab_xon_lan +
                              sum_cost_check_ton + sum_car_external_commission + sum_sales_commission + sum_additional_commission + sum_advertising_cost + sum_delivery_fee +
                              sum_promotion_fee + sum_finance_fee + sum_transfer_fee + sum_cost_after_sales_service + sum_cost_phra + sum_cost_vat_and_property_tax)
        }

       //console.log('sum_vat_sell =', sum_vat_sell, typeof sum_vat_sell)

        this.setState({
          lists: res.data,
          sum_price_normal: sum_price_normal,
          sum_price: sum_price,
          sum_car_cost: sum_car_cost,
          sum_car_cost_commissions: sum_car_cost_commissions,
          sum_car_cost_repair: sum_car_cost_repair,
          sum_car_cost_other: sum_car_cost_other,
          sum_profit: sum_profit,
          sum_car_cost_tax: sum_car_cost_tax,
          sum_car_cost_act: sum_car_cost_act,
          //
          sum_car_external_commission: sum_car_external_commission,
          sum_sales_commission: sum_sales_commission,
          sum_additional_commission: sum_additional_commission,
          sum_advertising_cost: sum_advertising_cost,
          sum_delivery_fee: sum_delivery_fee,
          sum_promotion_fee: sum_promotion_fee,
          sum_finance_fee: sum_finance_fee,
          sum_transfer_fee: sum_transfer_fee,
          //
          sum_vat_sell: sum_vat_sell,
          sum_finance_commission: sum_finance_commission,
          sum_vat_commission: sum_vat_commission,
          sum_cost_phra: sum_cost_phra,
          sum_cost_vat_and_property_tax: sum_cost_vat_and_property_tax,
          isLoading: false,

          person_vat_id: person_vat_id,
          employee_id: employee_id,

          //
            sum_cost_operation: sum_cost_operation,
            sum_cost_service: sum_cost_service,
            sum_cost_transport: sum_cost_transport,
            sum_cost_porobor_lan: sum_cost_porobor_lan,
            sum_cost_bangkhab_phasi_lan: sum_cost_bangkhab_phasi_lan,
            sum_cost_bangkhab_xon_lan: sum_cost_bangkhab_xon_lan,
            sum_cost_check_ton: sum_cost_check_ton,
            sum_cost_after_sales_service: sum_cost_after_sales_service,

            sum_tax_invoice_vat : sum_tax_invoice_vat,
            sum_vat_buy: sum_vat_buy,
            sum_car_cost_vat : sum_car_cost_vat,
            sum_financing_amount : sum_financing_amount,
            sum_down_total: sum_down_total
        })
      }).catch(error => {
        console.log('error:', error)
       // this.setState({lists: []})
    })

  }


  loadEmployeeData() {
        axios.get(APIURL + '/employee/')
            .then(res => {
                if(res.status === 200) {
                    let data = res.data
                    if (data.length > 0) {
                        let dataFilter = data.filter(d => d.employee_position.indexOf('ขาย') !== -1)
                        this.setState({ employees: dataFilter })
                    } else {
                        this.setState({employees: [] })
                    }
                }
            }).catch(error => {
            console.log('error:', error);
            this.setState({employees: [] })
        })
    }


    async exportSellData() {

        if(this.state.date_start > this.state.date_end){
            alert('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
            return
        }

        let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
        let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')
        const person_vat_id = this.state.person_vat_id
        const employee_id = this.state.employee_id

      const data = {
          start_date: start_date,
          end_date: end_date,
          person_vat_id: person_vat_id,
          employee_id: employee_id
      }

        this.setState({
            isLoading: true
        },()=>{
            axios.post(APIURL + '/report/sellexport/', data)
                .then( async res => {
                    const excelBuffer = res.data.excelBuffer
                    const currentDatetime = res.data.currentDatetime
                    const fileName = 'รายงานสรุปยอดขาย_export_'+currentDatetime+'.xlsx'
                    this.setState({
                        isLoading: false
                    })
                    await save(excelBuffer, fileName)

                }).catch(error => {
                console.log('error:', error)
                // this.setState({lists: []})
            })
        })
    }

  getExpenseCountColumn() {
    if (PORT === 9005) {
      return 12
    } else  if (PORT === 9043) {
      return 25
    } else {
      return 23
    }
  }

  render () {

      let employee_list = this.state.employees.map((employee, index) => {
          return (
              <option key={index} value={employee.employee_id}>{employee.employee_name}&nbsp;&nbsp;{employee.employee_lastname}</option>
          )
      })

    const {
      lists,
      sum_price_normal,
      sum_price,
      sum_car_cost,
      sum_car_cost_commissions,
      sum_car_cost_repair,
      sum_car_cost_other,
      sum_profit,
      sum_car_cost_tax,
      sum_car_cost_act,
      //
      sum_car_external_commission,
      sum_sales_commission,
      sum_additional_commission,
      sum_advertising_cost,
      sum_delivery_fee,
      sum_promotion_fee,
      sum_finance_fee,
      sum_transfer_fee,
      sum_cost_phra,
      sum_cost_vat_and_property_tax,
      //
      sum_vat_sell,
      sum_finance_commission,
      sum_vat_commission,

      sum_cost_operation,
      sum_cost_service,
      sum_cost_transport,
      sum_cost_porobor_lan,
      sum_cost_bangkhab_phasi_lan,
      sum_cost_bangkhab_xon_lan,
      sum_cost_check_ton,
      sum_cost_after_sales_service,

      sum_tax_invoice_vat,
      sum_vat_buy,

      sum_car_cost_vat,
      sum_financing_amount,

      sum_down_total

    } = this.state

    const colIndexIncome = PORT === 9005 ? 1 : 1;
    const colIndexExpense = PORT === 9005 ? 3 : 4;

    const colIndex = PORT === 9005 ? 4 : 6;

    const colIndex2 = PORT === 9005 ? 7 : 10;

    const emptyBody = <tr><td colSpan={20} className="text-center text-danger" > ไม่พบข้อมูล </td></tr>

    const listRows = lists.map((car, index) => {
      const car_sell_type = car.car_sell_type_id
      const car_sell_date = moment(car.car_sell_date).locale('th').format('LL')
      const car_cost = car.car_cost
      const car_cost_commissions = car.car_cost_commissions // คอมฯซื้อเข้า
      const car_cost_repair = car.car_cost_repair

      const sum_down = car.sum_down //ยอดจัด
      const car_sell_type_name = car.car_sell_type_name
      const bank_name = car.bank_name

      // ค่าใช้จ่าย พ.ร.บ./ค่าภาษีรถยนต์
      const car_cost_tax = car.car_cost_tax
      // ค่าภาษีรถยนต์
      const car_cost_act = car.car_cost_act
      const car_cost_other = car.car_cost_other
      const car_cost_expenses_other = car.car_cost_expenses_other + car_cost_other

        //รวมรายจ่ายหลังขาย
        const total_expenses_after_sell = (car.cost_operation + car.cost_service + car.cost_transport + car.cost_porobor_lan + car.cost_bangkhab_phasi_lan +
            car.cost_bangkhab_xon_lan + car.cost_check_ton + car.car_external_commission + car.sales_commission +
            car.additional_commission + car.advertising_cost + car.delivery_fee + car.finance_fee + car.promotion_fee +
            car.transfer_fee + car.cost_after_sales_service + car.cost_phra + car.cost_vat_and_property_tax)

      const tax_invoice_amount = car.tax_invoice_amount > 0 ?  car.tax_invoice_amount : car.rate_finance //ยอดจัด
      const vat_invoice = car.vat_sell > 0 ? car.vat_sell : car.tax_invoice_vat //VAT ยอดจัด
      const commission_amount = car.tax_commission_amount > 0 ? car.tax_commission_amount : car.finance_commission  //ค่าคอม
      const vat_commission = car.tax_commission_vat > 0 ? car.tax_commission_vat : car.vat_commission  //VAT ค่าคอม



        //รวมรายจ่ายก่อนขาย
        const total_expenses_before_sell = car_cost + car.vat_buy + car_cost_commissions + car_cost_repair + car_cost_expenses_other + car_cost_tax + car_cost_act

        // เฉพาะ 9048 scauto
        // รวมรายจ่ายก่อนขาย + หลังขาย
        const total_expenses = total_expenses_before_sell + total_expenses_after_sell
        const financing_amount = tax_invoice_amount + vat_invoice // ยอดจัด + VAT
        const car_cost_vat = total_expenses //รวมทุนก่อน-หลังขาย + VAT ทุน

        // let profit_before_sell = (car.tax_invoice_amount === null || car.tax_invoice_amount === 0 || financing_amount === 0 ? car.real_sell_price : car.tax_invoice_amount) - (total_expenses_before_sell)
        let profit_before_sell = (car.tax_invoice_amount === null || car.tax_invoice_amount === 0  ? car.real_sell_price : car.tax_invoice_amount) - (total_expenses_before_sell)

        if(PORT === 9048){
            // ใช้ยอดจัด financing_amount = 0 ไม่ใส่ยอดจัด ให้ใช้ราคาขายจริง
            profit_before_sell = (car.tax_invoice_amount === null || car.tax_invoice_amount === 0 || financing_amount === 0 ? car.real_sell_price : car.tax_invoice_amount) - (total_expenses_before_sell)
        }

       // console.log(car_license_plate,' : financing_amount=', financing_amount)
       // console.log(car_license_plate,' : tax_invoice_vat=', car.tax_invoice_vat)
        /* console.log('car.tax_invoice_amount=', car.tax_invoice_amount)
        console.log('car.real_sell_price=', car.real_sell_price)
        console.log('car.tax_invoice_amount=', car.tax_invoice_amount)
        console.log('car.total_expenses_before_sell=', total_expenses_before_sell) */


        if(PORT === 9005 || PORT === 9008 || PORT === 9025){
            profit_before_sell = car.real_sell_price - (total_expenses_before_sell)
        }

        let sell_price = car.tax_invoice_amount === null || car.tax_invoice_amount === 0 ? car.real_sell_price : car.tax_invoice_amount
        /*let sell_price = 0
        if(car_sell_type === 1){
            sell_price = car.tax_invoice_amount === null || car.tax_invoice_amount === 0 ? car.real_sell_price : car.tax_invoice_amount
        } else if (car_sell_type === 2) {
            // จัดไฟแนนซ์
            sell_price = car.tax_invoice_amount === null || car.tax_invoice_amount === 0 ? car.rate_finance : car.tax_invoice_amount
        }*/

        if(PORT === 9005 || PORT === 9008 || PORT === 9025){
            sell_price = car.real_sell_price
        }

        if(PORT === 9048){
            if(car_sell_type === 1){
                sell_price = car.tax_invoice_amount === null || car.tax_invoice_amount === 0 || financing_amount === 0 ? car.real_sell_price : car.tax_invoice_amount
            }else {
                sell_price = car.tax_invoice_amount === null || car.tax_invoice_amount === 0 ? financing_amount : car.tax_invoice_amount
            }
        }

        // rate_finance
        const car_license_plate = car.car_license_plate_new === '' ?  car.car_license_plate_old : car.car_license_plate_new
        const profit_after_sell = (vat_invoice + commission_amount + vat_commission) - total_expenses_after_sell

        let gross_profit = profit_before_sell + profit_after_sell //ช่องคำนวณกำไร
        // console.log('------------------ ', car_license_plate)
        // console.log('1..', gross_profit, car_license_plate)
        if(PORT === 9005 || PORT === 9008 || PORT === 9025){
            gross_profit = profit_before_sell + profit_after_sell
            // console.log('2..')
        }else if(sum_down !== 0 && sum_down !== financing_amount){
            if (PORT === 9048) {
              gross_profit = profit_before_sell + profit_after_sell + sum_down
              // console.log('3..', gross_profit, car_license_plate)
              // console.log('4..', profit_before_sell, profit_after_sell, sum_down)
            }
        }

      const person_vat_id = car.person_vat_id

      const province_name = car.car_license_plate_new !== '' ||  car.car_license_plate_new === 999 &&  car.car_license_plate_new !==  car.car_license_plate_old ? car.province_name_new : car.province_name_old

      const vat_buy = car.vat_buy

        {/*<td className="text-right text-nowrap">{car.real_sell_price === null ? '-' : car.real_sell_price.toLocaleString()}</td>*/}
        {/*sc ขอเพิ่มประเภทรถ*/}
      return (
        <tr key={index} style={index%2===0? { backgroundColor: ''} : { backgroundColor: '#e4e5e6'}}>
          <td className="text-center text-nowrap">{index+1}</td>
          <td className="text-nowrap" style={{width: 200}}>{car.car_name} <br/>( {car_license_plate} {province_name})</td>
          <td className={PORT === 9048 ? 'hidden' : 'text-center text-nowrap'}>{car.car_brand_name}</td>

          <td className={PORT === 9005 ? 'hidden' : 'text-center text-nowrap'}>{CarUtil.convertVatPersonToName(person_vat_id)}</td>

          <td className="text-nowrap" style={{width: 110}}>{car_sell_date}</td>
          <td style={{width: 60}} className="text-center text-nowrap">{car.employee_name}</td>

            <td className={PORT !== 9048 ? 'hidden' : 'text-center text-nowrap'} >{car_sell_type_name}</td>{/*sc เพิ่ม ประเภทการขาย*/}
            <td className={PORT !== 9048 ? 'hidden' : 'text-center text-nowrap'} >{bank_name}</td>{/*sc เพิ่ม ไฟแนนซ์*/}
            <td className={PORT !== 9048 ? 'hidden' : 'text-center text-nowrap'} >{car_cost_vat.toLocaleString()}</td> {/* ราคาทุน+VATทุน*/}
            <td className={PORT !== 9048 ? 'hidden' : 'text-center text-nowrap'} >{financing_amount.toLocaleString()}</td> {/*sc เพิ่ม ราคาจัด+VAT*/}
            <td className={PORT !== 9048 ? 'hidden' : 'text-center text-nowrap'} >{sum_down.toLocaleString()}</td> {/*sc เงินดาวน์*/}

          <td className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'} >{car.normal_sell_price === null ? '-' : car.normal_sell_price.toLocaleString()}</td>

          <td className="text-right text-nowrap">{sell_price.toLocaleString()}</td>
          <td className="text-right text-nowrap">{vat_invoice.toLocaleString()}</td>
          <td className="text-right text-nowrap">{commission_amount === null ? '-' : commission_amount.toLocaleString()}</td>
          <td className={PORT === 9005 ? 'hidden' : "text-right text-nowrap"} >{vat_commission === null ? '-' : vat_commission.toLocaleString()}</td>


          <td className="text-right text-nowrap">{car.car_cost === null ? '-' : car.car_cost.toLocaleString()}</td>
          <td className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'}>{vat_buy === null ? '-' : vat_buy.toLocaleString()}</td>

          <td className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'} >{car.car_cost_commissions === null ? '-' : car.car_cost_commissions.toLocaleString()}</td>

          <td className="text-right text-nowrap">{car.car_cost_repair === null ? '-' : car.car_cost_repair.toLocaleString()}</td>
          <td className="text-right text-nowrap">{car.car_cost_tax === null ? '-' : car.car_cost_tax.toLocaleString()}</td>
          <td className="text-right text-nowrap">{car.car_cost_act === null ? '-' : car.car_cost_act.toLocaleString()}</td>

          <td className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'}>{car.car_cost_expenses_other === null ? '-' : car.car_cost_expenses_other.toLocaleString()}</td>

          <td className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'}>{car.cost_operation === null ? '-' : car.cost_operation.toLocaleString()}</td>
          <td className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'}>{car.cost_service === null ? '-' : car.cost_service.toLocaleString()}</td>
          <td className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'}>{car.cost_transport === null ? '-' : car.cost_transport.toLocaleString()}</td>
          <td className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'}>{car.cost_porobor_lan === null ? '-' : car.cost_porobor_lan.toLocaleString()}</td>
          <td className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'}>{car.cost_bangkhab_phasi_lan === null ? '-' : car.cost_bangkhab_phasi_lan.toLocaleString()}</td>
          <td className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'}>{car.cost_bangkhab_xon_lan === null ? '-' : car.cost_bangkhab_xon_lan.toLocaleString()}</td>
          <td className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'}>{car.cost_check_ton === null ? '-' : car.cost_check_ton.toLocaleString()}</td>

          <td className="text-right text-nowrap">{car.car_external_commission === null ? '-' : car.car_external_commission.toLocaleString()}</td>
          <td className="text-right text-nowrap">{car.sales_commission === null ? '-' : car.sales_commission.toLocaleString()}</td>
          <td className="text-right text-nowrap">{car.additional_commission === null ? '-' : car.additional_commission.toLocaleString()}</td>
          <td className="text-right text-nowrap">{car.advertising_cost === null ? '-' : car.advertising_cost.toLocaleString()}</td>
          <td className="text-right text-nowrap">{car.delivery_fee === null ? '-' : car.delivery_fee.toLocaleString()}</td>
          <td className="text-right text-nowrap">{car.promotion_fee === null ? '-' : car.promotion_fee.toLocaleString()}</td>
          <td className="text-right text-nowrap">{car.finance_fee === null ? '-' : car.finance_fee.toLocaleString()}</td>
          <td className="text-right text-nowrap">{car.transfer_fee === null ? '-' : car.transfer_fee.toLocaleString()}</td>

          <td className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'}>{car.cost_after_sales_service === null ? '-' : car.cost_after_sales_service.toLocaleString()}</td>

          <td className={PORT === 9043 ? 'text-right text-nowrap' : 'hidden'}> {car.cost_phra === null ? '-' : car.cost_phra.toLocaleString()} </td>
          <td className={PORT === 9043 ? 'text-right text-nowrap' : 'hidden'}> {car.cost_vat_and_property_tax === null ? '-' : car.cost_vat_and_property_tax.toLocaleString()} </td>

          <td className="text-right text-nowrap">{gross_profit.toLocaleString()}</td>
        </tr>
      )
    })

    const tableRows = lists.length === 0 ? emptyBody : listRows

    const tableSum = <tr key={'sum'}>
                  <td className="text-right font-weight-bold" colSpan={PORT === 9005 ? 5 : (PORT === 9048 ? 7 : 6)}>รวม</td>

                    <td className={PORT !== 9048 ? 'hidden' : 'text-right font-weight-bold'} >{sum_car_cost_vat.toLocaleString()}</td>{/* ราคาทุน+VATทุน*/}
                    <td className={PORT !== 9048 ? 'hidden' : 'text-right font-weight-bold'} >{sum_financing_amount.toLocaleString()}</td>{/*sc เพิ่ม ยอดจัด+VAT*/}
                    <td className={PORT !== 9048 ? 'hidden' : 'text-right font-weight-bold'} >{sum_down_total.toLocaleString()}</td>{/* เงินดาวน์*/}

                    <td className={PORT === 9005 ? 'hidden' : 'text-right font-weight-bold'} >{sum_price_normal.toLocaleString()}</td>

                  <td className="text-right font-weight-bold">{sum_price.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_tax_invoice_vat.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_finance_commission.toLocaleString()}</td>
                  <td className={PORT === 9005 ? 'hidden' : 'text-right font-weight-bold'} >{sum_vat_commission.toLocaleString()}</td>

                  <td className="text-right font-weight-bold">{sum_car_cost.toLocaleString()}</td>
                  <td className={PORT === 9005 ? 'hidden' : 'text-right font-weight-bold'}>{sum_vat_buy.toLocaleString()}</td>

                  <td  className={PORT === 9005 ? 'hidden' : 'text-right font-weight-bold'} >{sum_car_cost_commissions.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_car_cost_repair.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_car_cost_tax.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_car_cost_act.toLocaleString()}</td>
                  <td  className={PORT === 9005 ? 'hidden' : 'text-right font-weight-bold'} >{sum_car_cost_other.toLocaleString()}</td>

                    <td  className={PORT === 9005 ? 'hidden' : 'text-right font-weight-bold'} >{sum_cost_operation.toLocaleString()}</td>
                    <td  className={PORT === 9005 ? 'hidden' : 'text-right font-weight-bold'} >{sum_cost_service.toLocaleString()}</td>
                    <td  className={PORT === 9005 ? 'hidden' : 'text-right font-weight-bold'} >{sum_cost_transport.toLocaleString()}</td>
                    <td  className={PORT === 9005 ? 'hidden' : 'text-right font-weight-bold'} >{sum_cost_porobor_lan.toLocaleString()}</td>
                    <td  className={PORT === 9005 ? 'hidden' : 'text-right font-weight-bold'} >{sum_cost_bangkhab_phasi_lan.toLocaleString()}</td>
                    <td  className={PORT === 9005 ? 'hidden' : 'text-right font-weight-bold'} >{sum_cost_bangkhab_xon_lan.toLocaleString()}</td>
                    <td  className={PORT === 9005 ? 'hidden' : 'text-right font-weight-bold'} >{sum_cost_check_ton.toLocaleString()}</td>

                  <td className="text-right font-weight-bold">{sum_car_external_commission.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_sales_commission.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_additional_commission.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_advertising_cost.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_delivery_fee.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_promotion_fee.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_finance_fee.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_transfer_fee.toLocaleString()}</td>

                  <td  className={PORT === 9005 ? 'hidden' : 'text-right font-weight-bold'} >{sum_cost_after_sales_service.toLocaleString()}</td>

                  <td className={PORT === 9043 ? 'text-right font-weight-bold' : 'hidden'} >{sum_cost_phra.toLocaleString()}</td>
                  <td className={PORT === 9043 ? 'text-right font-weight-bold' : 'hidden'} >{sum_cost_vat_and_property_tax.toLocaleString()}</td>

                  <td className="text-right font-weight-bold">{sum_profit.toLocaleString()}</td>
                </tr>

    return (
      <div>
        <Loading isLoading={this.state.isLoading} />

        <div className="card-block" style={styles.overflowY}>
          <div className="row">

              <div className="card card-accent-primary">
                <div className="card-header">
                  <strong className="text-title">รายงานการขาย</strong>
                </div>
                <div className="card-block">

                  <div className="row mt-1">
                    <div className="mt-1 ml-3">
                      จาก
                    </div>

                    <div className="col-1">
                      <DatePicker
                        selected={this.state.date_start}
                        onChange={this.handleDateStart}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                    <div className="mt-1 ml-3">
                      ถึง
                    </div>
                    <div className="col-1">
                      <DatePicker
                        selected={this.state.date_end}
                        onChange={this.handleDateEnd}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control d-block"
                        placeholder=""
                      />
                    </div>

                      <div className="form-group ml-3 mr-3" style={{width: 160}}>
                          <select className="form-control"
                                  id="person_vat_id"
                                  name="person_vat_id"
                                  value={this.state.person_vat_id}
                                  onChange={(e) => {
                                      this.setState({
                                          person_vat_id : e.target.value
                                      })
                                  }}
                          >
                              <option value="0">ประเภทการซื้อ</option>
                              <option value="1">บุคคล/รถบ้าน</option>
                              <option value="2">รถ VAT</option>
                              <option value="3">รถฝากขาย</option>
                          </select>
                      </div>

                      <div className="form-group ml-3 mr-3" style={{width: 180}}>
                          <select className="form-control"
                                  id="employee_id"
                                  name="employee_id"
                                  value={this.state.employee_id}
                                  onChange={(e) => {
                                      //console.log('employee_id =', e.target.value)
                                      this.setState({
                                          employee_id: e.target.value
                                      })
                                  }}
                          >
                              <option value=''> เลือกพนักงานผู้ขาย </option>
                              {employee_list}
                          </select>
                      </div>

                    <div className="d-flex mr-3">
                      <button type="button" className="btn btn-sm btn-primary" onClick={() => this.loadSellData()} > ตกลง </button>
                    </div>

                    <div className="d-flex">
                      <button type="button" className="btn btn-sm btn-success" onClick={() => this.exportSellData()} > Excel </button>
                    </div>

                    <div className="col-5 text-right pt-2">
                      {/*<h5>
                        รวมยอดขาย&nbsp;
                        <NumberFormat
                          displayType={'text'}
                          thousandSeparator={true}
                          value={this.state.sum_expense}/>
                        &nbsp;บาท
                      </h5>*/}
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-12">
                      <table className="" border={1} style={{border: 1}} >
                        <thead>
                        <tr>
                          <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ลำดับ</th>
                          <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ชื่อรถ</th>
                          <th rowSpan={2} style={styles.headerTable} className={PORT === 9048 ? 'hidden' : 'text-center text-nowrap'}>ยี่ห้อ</th>
                          <th rowSpan={2} className={PORT === 9005 ? 'hidden' : 'text-center text-nowrap'} style={styles.headerTable} >ประเภท</th>
                          <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">วันที่ขาย</th>
                          <th rowSpan={2} style={styles.headerTable} className="text-center text-nowrap">ขายโดย</th>
                          <th rowSpan={2} style={styles.headerTable} className={PORT !== 9048 ? 'hidden' : 'text-center text-nowrap'}>ประเภทการขาย</th>
                          <th rowSpan={2} style={styles.headerTable} className={PORT !== 9048 ? 'hidden' : 'text-center text-nowrap'}>ไฟแนนซ์</th>
                            <th rowSpan={2} className={PORT !== 9048 ? 'hidden' : 'text-center text-nowrap'} style={styles.headerTable} >ทุน + VAT ทุน</th>
                          <th rowSpan={2} className={PORT !== 9048 ? 'hidden' : 'text-center text-nowrap'} style={styles.headerTable} >ยอดจัด + VAT</th>
                          <th rowSpan={2} className={PORT !== 9048 ? 'hidden' : 'text-center text-nowrap'} style={styles.headerTable} >เงินดาวน์</th>
                            <th rowSpan={2} className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'} style={styles.headerTable} >ราคาขาย(0)</th>

                          <th colSpan={PORT === 9005 ? 3 : 4} className="text-center text-nowrap" style={styles.rowReceive}  >รายรับ</th>
                          <th colSpan={this.getExpenseCountColumn()} className="text-center text-nowrap" style={styles.rowExpense} >รายจ่าย</th>

                          {/*<th rowSpan={2} className="text-center text-nowrap" style={styles.rowProfit} >กำไรเบื้องต้น({PORT === 9043? colIndex2 + 20 : colIndex2 + 18})</th>*/}
                          <th rowSpan={2} className="text-center text-nowrap" style={styles.rowProfit} >กำไรเบื้องต้น</th>

                        </tr>
                        <tr>

                          <th style={styles.rowReceive}  className="text-right text-nowrap">ราคาขายจริง/ยอดจัด({colIndexIncome})</th>
                          <th style={styles.rowReceive}  className="text-right text-nowrap">VAT ยอดจัด({colIndexIncome+1})</th>
                          <th style={styles.rowReceive}  className="text-right text-nowrap">ค่าคอมมิชชั่น FN({colIndexIncome+2})</th>

                          <th className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'} style={styles.rowReceive}>VAT คอมมิชชั่น({colIndexIncome+3})</th>

                          <th style={styles.rowExpense} className="text-right text-nowrap">{PORT !== 9048 ? 'ซื้อรถเข้า' : 'ทุนซื้อรถ'}({colIndexExpense+1}) </th>
                          <th className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'} style={styles.rowExpense}>{PORT !== 9048 ? 'VAT ซื้อเข้า' : ' VAT ทุน'} ({colIndexExpense+2}) </th>

                          <th className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'} style={styles.rowExpense} >ค่าคอมฯซื้อเข้า ({colIndexExpense+3}) </th>

                          <th style={styles.rowExpense} className="text-right text-nowrap">ค่าซ่อม({PORT === 9005 ? colIndexExpense + 2 :colIndexExpense + 4})</th>
                          <th style={styles.rowExpense} className="text-right text-nowrap">ค่าภาษีรถยนต์ ({PORT === 9005 ? colIndexExpense + 3 :colIndexExpense + 5})</th>
                          <th style={styles.rowExpense} className="text-right text-nowrap">ค่าใช้จ่าย พ.ร.บ. ({PORT === 9005 ? colIndexExpense + 4 :colIndexExpense + 6})</th>

                          <th className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'} style={styles.rowExpense} >ค่าใช้จ่ายรถอื่นๆ  ({colIndexExpense + 7})</th>

                          <th className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'} style={styles.rowExpense} >ค่าดำเนินการ  ({colIndexExpense + 8})</th>
                          <th className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'} style={styles.rowExpense} >ค่าบริการ  ({colIndexExpense + 9})</th>
                          <th className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'} style={styles.rowExpense} >ค่าขนย้าย  ({colIndexExpense + 10})</th>
                          <th className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'} style={styles.rowExpense} >ค่า พรบ.จากลาน  ({colIndexExpense + 11})</th>
                          <th className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'} style={styles.rowExpense} >ค่าบังคับต่อภาษีจากลาน  ({colIndexExpense + 12})</th>
                          <th className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'} style={styles.rowExpense} >ค่าบังคับโอนเข้าเต็นท์จากลาน  ({colIndexExpense + 13})</th>
                          <th className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'} style={styles.rowExpense} >ค่าเช็คต้น  ({colIndexExpense + 14})</th>

                          <th style={styles.rowExpense} className="text-right text-nowrap">ค่านายหน้า({PORT === 9005 ? colIndex2 +1 : colIndex2 + 9})</th>
                          <th style={styles.rowExpense} className="text-right text-nowrap">ค่าคอมมิชชั่นฝ่ายขาย({PORT === 9005 ? colIndex2 +2 : colIndex2 + 10})</th>
                          <th style={styles.rowExpense} className="text-right text-nowrap">ค่าคอมมิชชั่นเพิ่มเติม({PORT === 9005 ? colIndex2 +3 :colIndex2 + 11})</th>
                          <th style={styles.rowExpense} className="text-right text-nowrap">ค่าโฆษณา({PORT === 9005 ? colIndex2 +4 :colIndex2 + 12})</th>
                          <th style={styles.rowExpense} className="text-right text-nowrap">ค่าบริการส่งรถ({PORT === 9005 ? colIndex2 +5 :colIndex2 + 13})</th>
                          <th style={styles.rowExpense} className="text-right text-nowrap">ค่าโปรโมชั่น({PORT === 9005 ? colIndex2 +6 :colIndex2 + 14})</th>
                          <th style={styles.rowExpense} className="text-right text-nowrap">ค่าธรรมเนียม FN({PORT === 9005 ? colIndex2 +7 :colIndex2 + 15})</th>
                          <th style={styles.rowExpense} className="text-right text-nowrap">ค่าโอนรถ({PORT === 9005 ? colIndex2 +8 : colIndex2 + 16})</th>

                          <th className={PORT === 9005 ? 'hidden' : 'text-right text-nowrap'} style={styles.rowExpense} >บริการหลังการขาย({colIndex2 + 17})</th>

                          <th className={PORT === 9043 ? '' : 'hidden'} style={styles.rowExpense}>พระ({colIndex2 + 18})</th>
                          <th className={PORT === 9043 ? 'text-right text-nowrap' : 'hidden'} style={styles.rowExpense}>ภาษีมูลค่าเพิ่ม + ภาษีโรงเรือน({colIndex2 + 19})</th>

                        </tr>
                        </thead>
                        <tbody>
                         {tableRows}
                         {tableSum}
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>

          </div>
        </div>

      </div>
    )
  }
}

// eeede8
const styles = {
  headerTable: {
    backgroundColor: '#eeede8',
    verticalAlign: 'middle',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowReceive: {
    backgroundColor: '#bfcad0',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowExpense: {
    backgroundColor: '#ede4e3',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowProfit: {
    backgroundColor: '#95E06C',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  overflowY: {
    overflow: 'scroll',
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

// export default SellReport;
export default connect(mapStateToProps)(SellReport);
