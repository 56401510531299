import React from 'react'
// export const DocUtil = {
//   isPDF(fileNameOrUrl) {
//     const splitData = fileNameOrUrl.split('.');
//     const lastIndex = splitData.length - 1
//     const ext = splitData[lastIndex]
//     console.log('ext=', ext)
//     const isPdf = ext === 'pdf' ? true : false
//     console.log('isPdf=', isPdf)
//     return isPdf
//   }
// }

class DocUtil {
  isPDF(fileNameOrUrl) {
    const splitData = fileNameOrUrl.split('.');
    const lastIndex = splitData.length - 1
    const ext = splitData[lastIndex]
    const isPdf = ext === 'pdf' ? true : false
    return isPdf
  }

  isDoc(fileNameOrUrl) {
    const splitData = fileNameOrUrl.split('.');
    const lastIndex = splitData.length - 1
    const ext = splitData[lastIndex]
    const isDoc = ext === 'doc' || ext === 'docx' ? true : false
    return isDoc
  }

  isImage(fileName) {
    return fileName.match(/.(jpg|jpeg|png|gif)$/i)
  }

  replaceStar(str) {
    let len = str.length

    if(len < 10){
      return str;
    }

    let first = str.substring(0,3)
    let last = str.substring(len-4, len-1)

    let star = Array(len-6).join("*")
    let newStr = first+star+last
    return newStr
  }

  getCarDocTypeName(car_doc_type) {
    if(car_doc_type === 'normal') {
      return 'เอกสารรถ'
    } else if (car_doc_type === 'secret') {
      return 'เอกสารลับ'
    } else if (car_doc_type === 'sell_doc') {
      return 'เอกสารขาย'
    }else if (car_doc_type === 'web_doc') {
      return 'ภาพรับรถ / ภาพรีวิว'
    }
  }

  getDebtStatusName(debt_status) {
    let name = ''
    let color = ''
    switch (debt_status) {
      case 'normal':
        name = 'ปกติ'
        color = 'badge badge-primary'
        break;
      case 'npl':
        name = 'หนี้เสีย'
        color = 'badge badge-danger'
        break;
      case 'request':
        name = 'ขอปิดหนี้'
        color = 'badge badge-warning'
        break;
      case 'complete':
        name = 'จ่ายครบปิดหนี้'
        color = 'badge badge-success'
        break;
      default:
        name = ''
        break;
    }
    return <span className={color}>{name}</span>
  }

  getDebtStatusList(user_type) {
    if (user_type === 'admin') {
      return [
        {debt_status: 'normal', debt_status_name: 'ปกติ'},
        {debt_status: 'npl', debt_status_name: 'หนี้เสีย'},
        {debt_status: 'request', debt_status_name: 'ขอปิดหนี้'},
        {debt_status: 'complete', debt_status_name: 'จ่ายครบปิดหนี้'},
      ]
    } else {
      return [
        {debt_status: 'normal', debt_status_name: 'ปกติ'},
        {debt_status: 'npl', debt_status_name: 'หนี้เสีย'},
        {debt_status: 'request', debt_status_name: 'ขอปิดหนี้'},
      ]
    }
  }

  getExtFromKeyName(key_name) {
    if (key_name === null) {
      return ''
    }
    let arr_split = key_name.split('.')
    let last_index = arr_split.length - 1
    return arr_split[last_index]
  }


}

export default new DocUtil();
