import React, { Component } from 'react';
import {APIURL} from "../../config/config";
import axios from "axios";
import Switch from "react-switch";
import Swal from "sweetalert2";
import {AlertSuccess} from "../Alert/Alert";
import Loading from "../Loading";


class OccupationList extends Component{
    constructor (props) {
        super(props)
        this.state = {
            actionType: "บันทึก",
            occupations: [],
            occupation_id: '',
            occupation_name: '',
            occupation_order: '',
            isLoading: false
        }
        this.loadOccupation = this.loadOccupation.bind(this)
        this.onChangeSwitch = this.onChangeSwitch.bind(this)
        this.clearForm = this.clearForm.bind(this)
        this.deleteOccupation = this.deleteOccupation.bind(this)
        this.setEditForm = this.setEditForm.bind(this)
        this.onRefresh = this.onRefresh.bind(this)
    }

    componentDidMount () {
        this.loadOccupation()
    }

    onRefresh() {
        this.clearForm()
        this.loadOccupation()
    }

    loadOccupation() {

        this.setState({
            isLoading: true,
            occupations: []
        },() => {
            let url = APIURL+'/occupation/admin'
            axios.get(url).then(res => {

                this.setState({
                    occupations: res.data
                })

                setTimeout(()=>{
                    this.setState({
                        isLoading: false,
                    })
                }, 1200)

            })
        })
    }

    onChangeSwitch(checked, occupation){
        let occupations = this.state.occupations
        let item_update = occupation
        let item_index = occupations.findIndex(data => data.occupation_id=== occupation.occupation_id)
        if(checked === false){
            item_update.occupation_status = 'hidden'
        } else {
            item_update.occupation_status = 'show'
        }

        let data = {
            occupation_id: item_update.occupation_id,
            occupation_status: item_update.occupation_status,
        }

        let url = APIURL+'/occupation/status'
        axios.put(url, data).then(res => {

        })

        occupations[item_index] = item_update
        this.setState({
            occupations: occupations
        })
    }

    setEditForm(occupation) {
        this.setState({
            actionType: 'แก้ไข',
            occupation_id: occupation.occupation_id,
            occupation_name: occupation.occupation_name,
            occupation_order: occupation.occupation_order,
        })
    }

    deleteOccupation(occupation) {
        let occupation_id = occupation.occupation_id
        let occupation_name = occupation.occupation_name
        // let username = this.props.username
        let data = {
            occupation_id: occupation_id,
            occupation_name: occupation_name,
            occupation_status: 'delete',
        }

        Swal.fire({
            title: 'ยืนยันการลบ '+occupation_name+' ?',
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
        }).then((result) => {
            if (result.value) {
                let url = APIURL+'/occupation/status'
                axios.put(url, data).then(res => {
                    if(res.data === 'success') {
                        AlertSuccess('ลบข้อมูลแล้ว')
                        this.loadOccupation()
                    }
                })
            }
        });
    }

    clearForm() {
        this.setState({
            actionType: "บันทึก",
            occupation_name: '',
            occupation_id: '',
            occupation_order: ''
        })
    }

    saveOccupation() {
        let occupations = this.state.occupations
        let actionType = this.state.actionType
        let occupation_name = this.state.occupation_name
        // let occupation_order = occupations.length + 1
        let occupation_order = this.state.occupation_order === '' ?  occupations.length + 1 : this.state.occupation_order

        let data = {
            occupation_name: occupation_name,
            occupation_order: occupation_order
        }

        if(actionType === "บันทึก") {
            let url = APIURL+'/occupation'
            axios.post(url, data).then(res => {
                if(res.data === 'success') {
                    AlertSuccess('บันทึกข้อมูลแล้ว')
                    this.clearForm()
                    this.loadOccupation()
                }
            })
        } else if (actionType === "แก้ไข") {
            let occupation_id = this.state.occupation_id
            data = {...data, occupation_id: occupation_id }
            let url = APIURL+'/occupation/'+occupation_id
            axios.put(url, data).then(res => {
                if(res.data === 'success') {
                    AlertSuccess('แก้ไขข้อมูลแล้ว')
                    this.clearForm()
                    this.loadOccupation()
                }
            })
        }

    }

    render() {
        const {occupations} = this.state
        let occupationsTr = occupations.map((occupation, index) => (
            <tr key={index}>
                <td style={{width: 10}}>{ index+1 }</td>
                <td className="text-nowrap" style={{width: 80}}>
                    { occupation.occupation_name }
                    {
                        occupation.customer_occupation_id_null !== null ? <>
                         <br/>
                         <span className="text-danger" >
                             *** ไม่สามารถลบได้ เนื่องจากมีการใช้อาชีพที่ข้อมูลลูกค้า
                         </span>
                        </> : null
                    }
                </td>
                <td className="text-nowrap text-center" style={{width: 50}}>

                    { occupation.occupation_status === 'show' ? 'ใช้งาน' : 'ไม่ใช้งาน' }

                    <br/>

                    <Switch height = {20} width ={48}
                            onChange={(checked) => this.onChangeSwitch(checked, occupation)}
                            checked={occupation.occupation_status === 'show'}
                    />

                </td>
                <td className="text-center" style={{width: 120}}>
                    <button type="button"
                            className="btn btn-sm btn-warning btn-md-width mr-2 mb-2"
                            onClick={() => this.setEditForm(occupation)}
                    >
                        <i className="fa fa-edit"/> แก้ไข
                    </button>
                    <button type="button"
                            className="btn btn-sm btn-danger btn-md-width mr-2 mb-2"
                            disabled={occupation.customer_occupation_id_null !== null}
                            onClick={() => {
                                this.deleteOccupation(occupation)
                            }}
                    >
                        <i className="fa fa-remove"/> ลบ
                    </button>
                </td>
            </tr>
        ))
        return (
            <div className="row">

                <Loading isLoading={this.state.isLoading} />

                <div className="col-7">
                    <div className="card card-accent-info">
                        <div className="card-header">
                            <strong className="text-title">
                                ตั้งค่าอาชีพ
                            </strong>
                            <button className="btn btn-head-bar ml-2"
                                    style={styles.buttonHeight}
                                    onClick={() => this.onRefresh()}
                            >
                                <i className="icon-refresh"/>
                            </button>
                        </div>
                        <div className="card-block" style={styles.overflowY}>
                            <table className="table table-striped table-responsive">
                                <thead>
                                <tr>
                                    <th className="text-left">#</th>
                                    <th className="text-left">ชื่ออาชีพ</th>
                                    <th className="text-center">ใช้งาน</th>
                                    <th className="text-center">จัดการ</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    occupationsTr
                                }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <div className="col-5">
                    <div className="card card-accent-success">
                        <div className="card-header card-customer">
                            <strong className="text-title">เพิ่ม/แก้ไข อาชีพ</strong>
                        </div>
                        <div className="card-block">
                            <div className="form-group">
                                <label htmlFor="occupation_name">ชื่ออาชีพ</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="occupation_name"
                                    placeholder="ชื่ออาชีพ"
                                    style={styles.inputSearch}
                                    value={this.state.occupation_name}
                                    onChange={(e) => {
                                        this.setState({
                                            occupation_name: e.target.value
                                        })
                                        // กระบะ เก๋ง แวน
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="occupation_order">ลำดับในรายการ</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="occupation_order"
                                    placeholder="ลำดับในรายการ"
                                    style={styles.inputSearch}
                                    value={this.state.occupation_order}
                                    onChange={(e) => {
                                        this.setState({
                                            occupation_order: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <button type="button"
                                    className="btn btn-sm btn-danger mr-2"
                                    style={styles.button}
                                    onClick={()=> this.clearForm()}
                            >
                                <i className="fa fa-refresh"/> ยกเลิก
                            </button>

                            <button type="button"
                                    className={this.state.actionType==="แก้ไข"?  "btn btn-sm btn-warning" : "btn btn-sm btn-primary"}
                                    style={styles.button}
                                    onClick={() => {
                                        this.saveOccupation()
                                    }}
                            >
                                <i className={this.state.actionType === "แก้ไข" ? "fa fa-edit" : "fa fa-save"}/> {this.state.actionType}
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    button: {
        borderRadius:5,
        height: 36,
    },
    inputSearch: {
        borderRadius:5,
    },
    overflowY: {
        overflow: 'scroll',
    },
    buttonHeight: {
        height: 36,
        paddingTop:0,
        paddingBottom:0,
        display: 'inline',
        marginTop: '0rem',
        borderRadius:5,
    }
}

export default  OccupationList
